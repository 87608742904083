<template>
  <PrintForm
    :print="print ? print : getCurrentPrint()"
    :type="'edit'"
    @save="updatePrint"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission.js'
export default {
  components: {
    PrintForm: () => import('./PrintForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    print: { type: 'print' },
    askToLeave: true,
  }),
  methods: {
    getCurrentPrint() {
      this.$api
        .get(`product_prints/${this.$route.params.id}`)
        .then((res) => {
          this.print = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updatePrint() {
      this.askToLeave = false
      // const formData = new FormData()
      // const printKeys = Object.keys(this.print)
      // const printValues = Object.values(this.print)

      // for(let i = 0; i < printKeys.length; i++) {
      //   if(printValues[i] !== null) {
      //     formData.append(printKeys[i], printValues[i])
      //   }
      // }

      this.$api
        .post(`product_prints/${this.$route.params.id}`, this.print)
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Gravação atualizado com sucesso',
            confirm: () => {
              this.$router.push({ name: 'print' })
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar atualizar este gravação',
            caption: 'Verifique se os campos estão preenchidos corretamente.',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
  },
  created() {
    this.getCurrentPrint()
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
